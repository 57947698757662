import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/radu/code/ebe/src/components/BookLayout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Ce știu și ce cred eu despre Dumnezeu? Ce cred despre Isus? Despre mântuire? Despre păcat? Despre viața veșnică? Despre Biblie? … De ce cred, CE cred? Cum pot fi sigur de adevăr?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Ca și creștin nu doar că trebuie să caut să am răspunsuri corecte la aceste întrebări, ci sunt responsabil să prezint aceste aspecte clar pentru cei care au întrebări raționale despre credință, să fiu în stare să apăr Evanghelia și principiile atunci când acestea sunt atacate de erezii.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Cursul este indicat pastorilor, învățătorilor și este de folos fiecărui creștin ce se lasă provocat.
Pe măsură ce-ți îmbunătățești înțelegerea credinței creștine, credința ta va fi întărită, autoritatea proclamării tale va crește și vei avea curaj să abordezi în predicare subiecte mai sensibile. Nu va fi ușor... dar câștigul este categoric mare!`}</MDXTag>
      <MDXTag name="p" components={components}>{`Citat: "Dovezile venind din partea observabilă a universului indică faptul că materia nu este eternă; ea a avut un început. Cauza ei trebuie să fie, în mod logic, eternă și neprovocată. și această cauză primară ar trebui să fie mult mai complexă, mai puternică și inteligentă decât efectul ei (universul). Deci universul nu poate fi produsul întâmplării."`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    